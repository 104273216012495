<template>
  <v-app-bar
    class="app-bar flex-grow-0 flex-shrink-0"
    :height="APP_HEADER_HEIGHT"
    flat
  >
    <v-container
      class="px-4 px-md-2"
      :class="['py-4']"
    >
      <v-row class="align-center">
        <NuxtLink
          :to="{ name: 'index' }"
          @click="handleClickLogo"
        >
          <v-img
            src="/images/logo/medflex.svg"
            alt="МедФлекс"
            width="159"
            height="32"
            class="cursor-pointer flex-grow-0"
          />
        </NuxtLink>

        <div class="d-none d-md-flex justify-end align-center flex-grow-1 app-bar-links">
          <NuxtLink
            v-for="(link) in links"
            :key="link.text"
            class="app-bar__link"
            :to="link.to"
            :class="{
              'app-bar__link_active': activeLink === link.to.hash && isMounted
            }"
          >
            {{ link.text }}
          </NuxtLink>
        </div>

        <v-spacer />

        <v-btn
          color="primary"
          variant="tonal"
          :href="loginLink"
        >
          Войти
        </v-btn>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script lang="ts">
import { SectionId } from '~/enums'
import { APP_HEADER_HEIGHT } from '~/constants'

export default defineComponent({
  name: 'AppHeader',
  setup() {
    const route = useRoute()
    const activeLink = computed(() => route.hash)
    const loginLink = `${useRuntimeConfig().public.mfUrl}/login/?next=/cabinet/`
    /* Исправление Hydration style mismatch https://github.com/vuetifyjs/vuetify/issues/19015 */
    const isMounted = useMounted()
    const links = [
      {
        text: 'Для страховых и сервисов',
        to: {
          name: 'index',
          hash: `#${SectionId.ForInsuranceService}`
        }
      },
      {
        text: 'Для клиник',
        to: {
          name: 'index',
          hash: `#${SectionId.ForClinic}`
        }
      },
      {
        text: 'Партнёры',
        to: {
          name: 'index',
          hash: `#${SectionId.Partners}`
        }
      },
      {
        text: 'Виджеты',
        to: {
          name: 'index',
          hash: `#${SectionId.Widgets}`
        }
      },
      {
        text: 'Интеграции с МИС',
        to: {
          name: 'index',
          hash: `#${SectionId.MisList}`
        }
      }
    ]

    function handleClickLogo() {
      if (route.name !== 'index') {
        return
      }

      scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

    return {
      isMounted,
      APP_HEADER_HEIGHT,
      activeLink,
      loginLink,
      links,
      handleClickLogo
    }
  }
})
</script>

<style lang="scss">
.app-bar {
  &__link {
    font-weight: 400;
    color: $t-black;
    text-decoration: none;

    &_active, &:hover {
      color: $t-primary
    }
  }

  &-links {
    column-gap: 16px;

    @media (min-width: 1000px) {
      column-gap: 32px;
    }
  }
}
</style>
